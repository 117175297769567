import { Component, forwardRef, Inject, ViewChild } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { RouterActions } from '@core/router/store';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ViewMainSelectorVisibilityDto, ViewTargets } from '../../../../core/services/api-clients';
import { NoEventWidgetDirective } from '../../../../core/widgets/directives/no-event-widget.directive';
import { WidgetDirective } from '../../../../core/widgets/directives/widget.directive';
import { IScriptRunnerService, SCRIPT_RUNNER_SERVICE } from '../../../../core/widgets/models/iscript-runner.service';
import { ColumnResizeEvent, ViewTab } from '../../models/view.model';
import {
  LoadEntityView,
  LoadGridSettingsFromLocalStorage,
  RestoreGridSettings,
  SaveGridColumnSettings,
  SaveGridToolbarSettings,
  SaveTabsVisibilitySettings,
} from '../../store/actions';
import { getEntityView, getGridSettings } from '../../store/selectors';
import { IViewState } from '../../store/state';
import { EngineViewComponent } from '../engine-view/engine-view.component';
import { GridSettings } from '../engine-view/services/engine-view-settings.service';
import {
  EngineViewStateService,
  ENGINE_VIEW_STATE_SERVICE,
  EngineFilterType,
} from '../engine-view/services/engine-view-state.service';
import { EntityViewWithTabs } from '../subgrid-view/subgrid-view.component';
import { RecordsSelectorViewConfig } from './records-selector-view-config';

@Component({
  selector: 'app-records-selector-view',
  templateUrl: './records-selector-view.component.html',
  styleUrls: ['./records-selector-view.component.scss'],
  providers: [
    {
      provide: WidgetDirective,
      useExisting: forwardRef(() => RecordsSelectorViewComponent),
    },
    {
      provide: ENGINE_VIEW_STATE_SERVICE,
      useClass: EngineViewStateService,
    },
  ],
})
export class RecordsSelectorViewComponent extends NoEventWidgetDirective {
  entityViewWithTabs$: Observable<EntityViewWithTabs>;
  settings$: Observable<GridSettings>;

  @ViewChild(EngineViewComponent, { static: false }) engineViewComponent: EngineViewComponent;

  get hasCustomFilters(): boolean {
    return (
      !!this.data?.query?.customFilter ||
      this.data?.query?.filter?.filters?.some((f: any) => !!f[EngineFilterType.Custom])
    );
  }

  constructor(
    @Inject(SCRIPT_RUNNER_SERVICE) scriptRunnerService: IScriptRunnerService,
    @Inject(ENGINE_VIEW_STATE_SERVICE) public stateService: EngineViewStateService,
    private _store: Store<IViewState>,
    private _dialogRef: MatDialogRef<RecordsSelectorViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RecordsSelectorViewConfig,
  ) {
    super(undefined, scriptRunnerService);
    this.initialize(this.data.viewId);
  }

  confirm(): void {
    if (this.data.onConfirm) {
      this.data.onConfirm(this.engineViewComponent.getSelectedRecords());
    }
    this._dialogRef.close();
  }

  cancel(): void {
    if (this.data.onCancel) {
      this.data.onCancel();
    }
    this._dialogRef.close();
  }

  // handlers

  onColumnResize(event: ColumnResizeEvent) {
    this._store.dispatch(
      new SaveGridColumnSettings({
        viewOrSubgridId: event.viewId,
        settings: event,
      }),
    );
  }

  onRowDoubleClick() {
    if (this.data.selectionMode == 'single') {
      this.confirm();
    }
  }

  // Toolbar buttons handlers

  onGlobalSearchChange(globalSearch: string) {
    this.engineViewComponent.stateService.setGlobalSearch(globalSearch);
    this.engineViewComponent.refreshData();
  }

  onGlobalSearchVisibilityChange(value: boolean) {
    this.resetQuery();

    this._store.dispatch(
      new SaveGridToolbarSettings({
        viewOrSubgridId: this.data.viewId,
        settings: { isGlobalSearchEnabled: value, areFiltersEnabled: value ? false : undefined },
      }),
    );
  }

  onFiltersVisibilityChange(value: boolean) {
    this.resetQuery();

    this._store.dispatch(
      new SaveGridToolbarSettings({
        viewOrSubgridId: this.data.viewId,
        settings: { areFiltersEnabled: value, isGlobalSearchEnabled: value ? false : undefined },
      }),
    );
  }

  onViewTabsVisibilityChange(viewTabs: ViewTab[]) {
    const selectedViewTab = viewTabs.find((x) => x.isVisible);
    if (selectedViewTab.viewId !== this.data.viewId) {
      this.initialize(selectedViewTab.viewId);

      this._store.dispatch(
        new SaveTabsVisibilitySettings({
          entityId: this.engineViewComponent.entityView.view.entity.id,
          tabsVisibilitySettings: viewTabs.map((viewTab) =>
            ViewMainSelectorVisibilityDto.fromJS({
              viewId: viewTab.viewId,
              isVisibleOnMainSelector: viewTab.isVisible,
            }),
          ),
        }),
      );
    }
  }

  onRefreshData() {
    this.engineViewComponent.refreshData();
  }

  onRestoreSettings() {
    this._store.dispatch(new RestoreGridSettings({ viewOrSubgridId: this.data.viewId }));
  }

  onNavigateToSettings() {
    this._store.dispatch(new RouterActions.NavigateToViewSettings({ viewId: this.engineViewComponent.getViewId() }));
  }

  // private methods

  initialize(viewId: string) {
    this.data.viewId = viewId;

    this._store.dispatch(new LoadEntityView({ viewId, viewTarget: ViewTargets.Expander }));
    this._store.dispatch(new LoadGridSettingsFromLocalStorage({ viewOrSubgridId: viewId }));

    this.entityViewWithTabs$ = this._store.pipe(
      select(getEntityView(viewId)),
      map((entityView) => {
        if (entityView == null) return null;

        const viewTabs = entityView.simpleViews.filter(x => (x.viewTargets & ViewTargets.Expander) == ViewTargets.Expander).map((x) => {
          return <ViewTab>{
            viewId: x.id,
            label: x.label,
            isVisible: viewId === x.id,
          };
        });

        if (viewTabs.find((x) => x.isVisible) == null) {
          viewTabs[0].isVisible = true;
        }

        return <EntityViewWithTabs>{
          entityView: entityView,
          viewTabs: viewTabs,
        };
      }),
      tap((_) => {
        if (this.data.query) {
          this.stateService.setSystemQuery(this.data.query);
        } else {
          this.stateService.setSystemQuery(undefined);
        }
      }),
    );

    this.settings$ = this._store.select(getGridSettings(viewId));
  }

  private resetQuery() {
    if (this.engineViewComponent.stateService.resetQuery()) {
      this.engineViewComponent.refreshData();
    }
  }
}
