import {
  Aggregator,
  AlignStyle,
  ColumnDto,
  DataType,
  DateTimeFormat,
  EntityViewDto,
  EnumDto,
  IconType,
  LinkBehavior,
  TimePrecision,
  WidthType,
} from '@core/services/api-clients';
import { SelectableMode } from '@progress/kendo-angular-grid';
import { AggregationType } from '@shared/grid/models/grid-data.model';
import {
  DateTimeFormatType,
  GridColumn,
  GridColumnAlign,
  GridColumnType,
  GridColumnWidthType,
  GridDefinition,
  IconColumnAlignItems,
  OptionSet,
  OptionSetValue,
} from '@shared/grid/models/grid-definition.model';
import { HyperlinkBehavior } from '@shared/hyperlink/hyperlink.model';
import { IconMode } from '../../../../../shared/ui-components/components/img-icon/img-icon.component';
import { GridSettings } from './engine-view-settings.service';

export class GridDefinitionBuilder {
  static build(
    entityView: EntityViewDto,
    settings: GridSettings,
    selectionMode: SelectableMode | 'none' = 'multiple',
  ): GridDefinition {
    if (entityView && settings) {
      return <GridDefinition>{
        columns: entityView.view.columns.map((column) => this.mapColumn(column)),
        isFilterable: settings.areFiltersEnabled,
        selection: {
          selectBy: 'Id',
          settings: {
            mode: selectionMode,
            enabled: selectionMode != 'none',
            checkboxOnly: false,
          },
        },
        columnsSettings: settings.columnsSettings,
        defaultQuery: JSON.parse(entityView.view.defaultQuery),
        defaultPageSize: entityView.view.pageSize,
      };
    }

    return null;
  }

  static getNormalizedColumnId(column: ColumnDto) {
    return `$${column.id.replace(/\-/g, '')}`;
  }

  static mapAggregator(aggregator: Aggregator): AggregationType {
    switch (aggregator) {
      case Aggregator.Average:
        return 'average';
      case Aggregator.Count:
        return 'count';
      case Aggregator.CountDistinct:
        return 'countDistinct';
      case Aggregator.Max:
        return 'max';
      case Aggregator.Min:
        return 'min';
      case Aggregator.Sum:
        return 'sum';
    }
  }

  private static mapColumn(column: ColumnDto): GridColumn {
    return <GridColumn>{
      columnId: this.getNormalizedColumnId(column),
      primaryField: column.primaryAttributeName,
      secondaryField: column.secondaryAttributeName,
      title: column.label,
      type: this.mapDataType(column.dataType),
      align: this.mapAlignStyle(column.align),
      textAlign: this.mapAlignStyle(column.textAlign),
      isFreezed: column.isFreezed,
      widthType: this.mapWidthType(column.widthType),
      width: column.width,
      optionSet: this.mapEnum(column.primaryEnum),
      dateTimeFormatType: this.mapDateTimeFormat(column.formatType),
      dateTimeFormat: column.customFormat,
      isClickable: column.relatedEntity != null,
      linkBehavior: this.mapLinkBehavior(column.linkBehavior),
      linkPlaceholder: column.linkPlaceholder,
      progressMinValue: column.progressMinValue,
      progressMaxValue: column.progressMaxValue,
      isOptionSet: !!column.primaryEnum,
      timeMaxPrecision: this.mapTimePrecision(column.timeMaxPrecision),
      timeMinPrecision: this.mapTimePrecision(column.timeMinPrecision),
      ratingMaxValue: column.ratingMaxValue,
      ratingMinValue: column.ratingMinValue,
      iconUrl: column.iconUrl,
      iconMode: this.mapIconType(column.iconType),
      icon: column.icon,
      alignItems: this.mapAlignItemsStyle(column.align),
      combinedTextsSeparator: column.combinedTextsSeparator,
      decimalPlaces: column.decimalPlaces,
      mask: column.mask
    };
  }

  private static mapDataType(dataType: DataType): GridColumnType {
    switch (dataType) {
      case DataType.Boolean:
        return 'boolean';
      case DataType.Date:
        return 'date';
      case DataType.DateTime:
        return 'datetime';
      case DataType.Decimal:
        return 'decimal';
      case DataType.Enum:
        return 'optionSet';
      case DataType.Guid:
        return 'guid';
      case DataType.Hyperlink:
        return 'hyperlink';
      case DataType.Integer:
        return 'integer';
      case DataType.Text:
        return 'text';
      case DataType.Progress:
        return 'progress';
      case DataType.Time:
        return 'time';
      case DataType.Rating:
        return 'rating';
      case DataType.Icon:
        return 'icon';
      case DataType.CombinedTexts:
        return 'combinedTexts';
      case DataType.Process:
        return 'process';
    }
  }

  private static mapAlignStyle(alignStyle: AlignStyle): GridColumnAlign {
    switch (alignStyle) {
      case AlignStyle.Center:
        return 'center';
      case AlignStyle.Left:
        return 'left';
      case AlignStyle.Right:
        return 'right';
    }
  }

  private static mapAlignItemsStyle(alignStyle: AlignStyle): IconColumnAlignItems {
    switch (alignStyle) {
      case AlignStyle.Center:
        return 'center';
      case AlignStyle.Left:
        return 'self-start';
      case AlignStyle.Right:
        return 'self-end';
    }
  }

  private static mapWidthType(widthType: WidthType): GridColumnWidthType {
    switch (widthType) {
      case WidthType.AdjustToSpace:
        return 'adjustToSpace';
      case WidthType.AdjustToText:
        return 'adjustToText';
      case WidthType.Percentage:
        return 'percentage';
      case WidthType.Pixel:
        return 'pixel';
    }
  }

  private static mapDateTimeFormat(dateTimeFormat: DateTimeFormat): DateTimeFormatType {
    switch (dateTimeFormat) {
      case DateTimeFormat.Custom:
        return 'custom';
      case DateTimeFormat.Long:
        return 'long';
      case DateTimeFormat.Short:
        return 'short';
    }
  }

  private static mapLinkBehavior(linkBehavior: LinkBehavior): HyperlinkBehavior {
    switch (linkBehavior) {
      case LinkBehavior.OpenInSamePage:
        return HyperlinkBehavior.OpenInSamePage;
      case LinkBehavior.OpenInNewTab:
        return HyperlinkBehavior.OpenInNewTab;
      case LinkBehavior.OpenInNewWindow:
        return HyperlinkBehavior.OpenInNewWindow;
      case LinkBehavior.OpenInEngineContext:
        return HyperlinkBehavior.OpenInEngineContext;
      default:
        return undefined;
    }
  }

  private static mapTimePrecision(precision: TimePrecision) {
    switch (precision) {
      case TimePrecision.Days:
        return 'days';
      case TimePrecision.Hours:
        return 'hours';
      case TimePrecision.Minutes:
        return 'minutes';
      case TimePrecision.Seconds:
        return 'seconds';
    }
  }

  private static mapIconType(iconType: IconType): IconMode {
    switch (iconType) {
      case IconType.Url:
        return 'url';
      case IconType.Icon:
        return 'icon';
    }
  }

  private static mapEnum($enum: EnumDto): OptionSet {
    if ($enum) {
      return <OptionSet>{
        name: $enum.fullName,
        isFlags: $enum.isFlags,
        values: $enum.options.map((x) => {
          return <OptionSetValue>{
            label: x.label,
            name: x.name,
            value: Number(x.value),
            color: x.htmlColor,
          };
        }),
      };
    } else {
      return null;
    }
  }
}
